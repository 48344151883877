
import Admin_layout from "./admin/admin_layout";
import { Dashboard } from "./admin/pages";
import SignIn from "./admin/components/loginpage";
import { LoginNew } from "./adminlog/Login/LoginNew";
import Error404 from "./Compoents/Error404";
import { FAQ } from "./Pages/Aboutus/FAQ/FAQ";
import { HowWeWork } from "./Pages/Aboutus/HowWeWork/HowWeWork";
import { SqureMedTech } from "./Pages/Aboutus/SqureMed/SqureMedTech";
import { TherapeuticSpecialties } from "./Pages/Aboutus/Therapeutic/TherapeuticSpecialties";
import BlogForm from "./Pages/blog/BlogForm";
import BlogReport from "./Pages/blog/BlogReport";
import CategoryManagement from "./Pages/blog/CategoryManagement";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/home";
import BlogList from "./Pages/blog/BlogList";
import Blogs from "./Pages/blog/Blogs";
import BlogDetails from "./Pages/blog/BlogsDetails";
import Regulations from "./Pages/Resources/Regulations";
import RegulationsDetails from "./Pages/Resources/RegulationsDetails";
import AcronymsAbbreviations from "./Pages/Resources/AcronymsAbbreviations";
import AcronymsAbbreviationsDetails from "./Pages/Resources/AcronymsAbbreviationsDetails";
import Training from "./Pages/Resources/Training";
import TrainingDetails from "./Pages/Resources/TrainingDetails";
import { PharmaceuticalDetails } from "./Pages/Solutions/PharmaceuticalDetails";
import { HealthcareDetails } from "./Pages/Solutions/HealthcareDetails";
import { MedicalDevice } from "./Pages/Solutions/MedicalDevice";
import { DigitalHealthSolutions } from "./Pages/Solutions/MedicalDevicelist/DigitalHealthSolutions";

const Routes =[
    {
        path:"/",
        element:<Home/>,
    },
    {
        path:"/contact-us",
        element:<Contact/>,
    },

    {
        path:"/blog-report",
        element:<BlogReport/>,
    },
    {
        path:"/category",
        element:<CategoryManagement/>,
    },
    {
        path:"/blogform",
        element:<BlogForm/>,
    },
    {
        path:"/square-medtech",
        element:<SqureMedTech/>,
    },
    {
        path:"/therapeutic-specialties",
        element:<TherapeuticSpecialties/>,
    },
    {
        path:"/how-we-work",
        element:<HowWeWork/>,
    },
    {
        path:"/faq",
        element:<FAQ/>,
    },
    {
        path:"/blog",
        element:<Blogs/>,
    },
    {
        path:"/BlogDetails/:id",
        element:<BlogDetails/>,
    },
    {
        path:"/regulations",
        element:<Regulations/>,
    },
    {
        path:"/regulations-details/:id",
        element:<RegulationsDetails/>,
    },
    {
        path:"/acronyms-abbreviations",
        element:<AcronymsAbbreviations/>,
    },
    {
        path:"/acronyms-abbreviations-details/:id",
        element:<AcronymsAbbreviationsDetails/>,
    },
    {
        path:"/training",
        element:<Training/>,
    },
    {
        path:"/pharmaceutical-details",
        element:<PharmaceuticalDetails/>,
    },
    {
        path:"/healthcare-details",
        element:<HealthcareDetails/>,
    },
    {
        path:"/digital-health-solutions",
        element:<DigitalHealthSolutions/>,
    },
    {
        path:"/medical-device-manufacturers",
        element:<MedicalDevice/>,
    },
    {
        path:"/training-details/:id",
        element:<TrainingDetails/>,
    },
    {
        path:"/login",
        element:<LoginNew/>,
    },
    {
        path:"/signin",
        element:<SignIn/>,
    },
    {
        path:"*",
        element:<Error404/>,
    },
    {
        path: "/admin",
        element: <Admin_layout />,
        children: [
            {
                path: "/admin/home",
                element: <Dashboard />,
            },
            // {
            //     path: "/admin/blog",
            //     element: <AdminBlog />,
            // },
            // {
            //     path: "/admin/blog-new",
            //     element: <BlogNew />,
            // },
            // {
            //     path: "/admin/gallery",
            //     element: <AdminGallery />,
            // },
            // {
            //     path: "/admin/product",
            //     element: <AdminMedicalProduct />,
            // },
            // {
            //     path: "/admin/video",
            //     element: <AdminVideo />,
            // },
            // {
            //     path: "/admin/reasearchform",
            //     element: <ResearchFaq />,
            // },
            // {
            //     path: "/admin/clinical-learning",
            //     element: <AdminClinicalLearningZone />,
            // },
            // {
            //     path: "/admin/contact-report",
            //     element: <ContactReport />,
            // },
        ],
    },

]
export default Routes;