import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain';
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../../Compoents/FooterWhite';
import { Link } from 'react-router-dom';

export const NotifyBodyA = () => {
    const breadcrumbLinks = [       
        { path: '/', label: 'Home' },
        { label: 'Notified Body Transition Details', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Notified Body Transition Details" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
<section className="service-details section-space"> 
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

          <h2 className="title-animation mb-20">Notified Body Transition</h2>

          <p className="mb-20">
            We offer a variety of solutions to efficiently manage transition projects. While each transition project is unique, our process remains consistent.
          </p>

          <h3 className="title-animation mb-15">
            QMS Improvement & Gap Identification:
          </h3>
          <ul className="mb-30">
            <li>
              <strong>QMS:</strong> SQUARE MEDTECH can assist medical device manufacturers in improving their quality management system (QMS). This involves updating the QMS if corrective and preventative measures are required, updated standards are released, or the regulatory landscape changes.
            </li>
            <li>
              <strong>Achieving success in quality management:</strong> The quality management system may need updates when expanding into new markets, updating regulations, or launching new business ventures. SQUARE MEDTECH specialists assist with gap studies and modifications to QMS and technical documentation.
            </li>
          </ul>

          <p className="mb-30">
            Our specialists ensure that your QMS and technical documentation are up-to-date and compliant with the latest regulations.
          </p>

          <h3 className="title-animation mb-15">
            How SQUARE MEDTECH Can Help:
          </h3>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Identifying changes that require QMS updates</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Conducting a systematic GAP analysis for existing QMS processes</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Assisting with interpreting the gap analysis and addressing identified gaps in QMS</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Performing GAP analyses for medical device technical documentation</h5>
            </li>
          </ul>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">Our Expertise in QMS and Digital Health</h4>
                <p>
                  SQUARE MEDTECH brings deep expertise in medical device QMS updates, helping manufacturers stay compliant with changing regulations and standards.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">Why Choose Us for QMS and Medical Device Services</h4>
                <p>
                  With our regulatory knowledge and software development skills, we provide comprehensive support to manufacturers navigating complex transitions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">QMS & Gap Identification Services</h4>
                        <ul  class="color-white mb-0">
                        <li> Planning & implementing</li>
                        <li> Methodical approach in QMS</li>
                        <li> Updating QMS when corrective & preventative actions required</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">QMS & Gap Identification Services</h4>
                        <ul  class="color-white mb-0">
                        <li>Internal audit Support</li>
                        <li>Systematic GAP analysis</li>
                        <li> Support when Regulatory changes</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    <FooterWhite/>

    </>
  )
}
