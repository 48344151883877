import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain';
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../../Compoents/FooterWhite';
import { Link } from 'react-router-dom';

export const NotifyBodyC = () => {
    const breadcrumbLinks = [       
        { path: '/', label: 'Home' },
        { label: 'Notified Body Transition Details', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Notified Body Transition Details" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech Industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Need Help with Medical Device Registration?
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          {/* Section Heading */}
          <h3 className="title-animation mb-15">c) Medical Device Registration</h3>
          <p>
            Medical device registration involves obtaining regulatory approval to market a device in a specific region. This process ensures that the device meets safety, efficacy, and quality standards set by local authorities. Successful registration is crucial for legal market entry and ongoing compliance.
          </p>

          {/* CE Registration */}
          <h4 className="title-animation mb-15">i) CE Registration</h4>
          <p>
            In the EU, medical device manufacturers must demonstrate their compliance with the MDR (Medical Device Regulation) by meeting the General Safety and Performance Requirements (GSPRs) specified in these regulations. The conformity assessment procedures vary based on the risk class of the device (Class I, Im, Ir, Is, IIa, IIb, III for MDR).
          </p>

          <h5 className="title-animation mb-15">Risk Classes and Conformity Assessment Procedures</h5>
          <ul className="mb-30">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Class I Devices:</strong> Typically, manufacturers can self-certify these devices without the involvement of a Notified Body.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Class Im, Ir, Is, IIa, IIb, and III Devices:</strong> These higher-risk classes usually require the involvement of a Notified Body.
            </li>
          </ul>

          <h5 className="title-animation mb-15">Steps for Compliance</h5>
          <h6 className="mb-10">1. For Devices Requiring a Notified Body:</h6>
          <ul className="mb-30">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Conformity Assessment:</strong> Select and undergo the applicable conformity assessment procedure with a Notified Body.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Certificate of Conformity:</strong> Obtain a certificate of conformity from the Notified Body.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Declaration of Conformity:</strong> Prepare and sign a Declaration of Conformity.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>CE Marking:</strong> Affix the CE mark to the device.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Additional Requirements:</strong> Fulfill all other items required in the conformity assessment procedure, such as technical documentation and post-market surveillance plans.
            </li>
          </ul>

          <h6 className="mb-10">2. For Devices Not Requiring a Notified Body:</h6>
          <ul className="mb-30">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Declaration of Conformity:</strong> Prepare and sign a Declaration of Conformity.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>CE Marking:</strong> Affix the CE mark to the device.
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <strong>Additional Requirements:</strong> Complete all items required in the selected conformity assessment procedure, including compiling technical documentation and conducting risk assessments.
            </li>
          </ul>

          <h4 className="title-animation mb-15">SQUARE MEDTECH Expertise</h4>
          <p>
            The SQUARE MEDTECH team has extensive experience with the MDR, encompassing a wide range of medical devices, ensuring smooth market entry for our clients. From planning to execution, we provide the expertise and resources necessary for successful medical device registration.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Evaluation</li>
                        <li>Clinical Investigation</li>
                        <li>Regulatory Life Cycle</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>PMCF Solution</li>
                        <li>Healthcare Survey</li>
                        <li>EU MDR/FDA/ NMPA Consulting</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

          <h2 className="title-animation mb-20">ii) FDA Registration</h2>
          <p className="mb-20">
            The increasing prevalence of software as a medical device (SaMD) or
            as part of a device, often incorporating AI, IoT, mobile apps, or
            telemedicine, is accompanied by evolving regulatory requirements and
            heightened information security concerns. These challenges
            necessitate a comprehensive approach to compliance and development.
          </p>

          <p className="mb-15">
            In the US, the regulatory framework for medical devices differs from
            that in Europe. Instead of Notified Bodies, the FDA (Food and Drug
            Administration) oversees the approval and regulation of medical
            devices (MD) and in vitro diagnostic (IVD) devices.
          </p>
          <h4 className="mb-15">Here is a detailed overview of the process:</h4>
          <ul className="mb-30">
            <li>
              <strong>Registration and Listing:</strong>
              <ul>
                <li>
                  <strong>Device Registration:</strong> All companies intending
                  to market medical devices in the US must register their
                  establishments with the FDA.
                </li>
                <li>
                  <strong>Device Listing:</strong> Companies must list their
                  devices with the FDA, providing necessary details about each
                  product.
                </li>
              </ul>
            </li>
            <li>
              <strong>Establishment Registration:</strong>
              <ul>
                <li>
                  <strong>Owners and Operators:</strong> Owners or applicable
                  operators of establishments involved in the production and
                  distribution of medical devices must also register with the
                  FDA.
                </li>
                <li>
                  <strong>Annual Renewal:</strong> Registration must be renewed
                  annually, ensuring ongoing compliance.
                </li>
              </ul>
            </li>
            <li>
              <strong>Documentation and Risk Classification:</strong>
              <ul>
                <li>
                  <strong>Risk Classes:</strong> Devices are classified into
                  three risk categories:
                  <ul>
                    <li>Class I: Low risk</li>
                    <li>Class II: Moderate risk</li>
                    <li>Class III: High risk</li>
                  </ul>
                </li>
                <li>
                  <strong>Product Codes:</strong> Each device is assigned a
                  product code that corresponds to its intended use and risk
                  classification.
                </li>
                <li>
                  <strong>Non-Classified Devices:</strong> Some devices do not
                  fit into these classifications and require specific regulatory
                  pathways.
                </li>
              </ul>
            </li>
            <li>
              <strong>FDA Approval Processes:</strong>
              <ul>
                <li>
                  <strong>510(k) Clearance:</strong> The most common submission
                  pathway for Class II devices, demonstrating substantial
                  equivalence to a legally marketed device.
                </li>
                <li>
                  <strong>De Novo Classification:</strong> A pathway for novel
                  devices that provide reasonable assurance of safety and
                  effectiveness for Class I or II designation.
                </li>
                <li>
                  <strong>Premarket Approval (PMA):</strong> Required for Class
                  III devices, involving rigorous evaluation of safety and
                  effectiveness through clinical trials and extensive
                  documentation.
                </li>
              </ul>
            </li>
          </ul>

          <h3 className="title-animation mb-15">SQUARE MEDTECH Expertise</h3>
          <p className="mb-30">
            The <strong>SQUARE MEDTECH</strong> team has in-depth knowledge and
            experience with the US FDA. We assist medical device manufacturers
            in achieving regulatory compliance and gaining market access
            efficiently.
          </p>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Our Expertise in Digital Health
                </h4>
                <p>
                  At <strong>SQUARE MEDTECH</strong>, our expertise in digital
                  health solutions ensures smooth project management, regulatory
                  compliance, and optimized performance for all medical device
                  developers.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Why Choose Us for Digital Health
                </h4>
                <p>
                  We bring together unparalleled regulatory knowledge and
                  software development skills to provide a comprehensive
                  approach to digital health projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Post Market Activities</li>
                        <li>Technical Documentation</li>
                        <li> Quality Management System</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li> QA / RA Service</li>
                        <li>Product Registrations </li>
                        <li> Digital Health Services</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

          <h2 className="title-animation mb-20">iii) NMPA Registration</h2>

          <p className="mb-20">
            The National Medical Products Administration (NMPA), formerly known as the China Food and Drug Administration (CFDA), is responsible for the regulation and approval of medical devices in China. Navigating the NMPA registration process is crucial for companies seeking to market their medical devices in the Chinese market.
          </p>

          <h3 className="title-animation mb-15">Detailed Overview of the Process</h3>
          <ul className="mb-30">
            <li>
              <strong>Documentation and Risk Classification:</strong> Medical devices in China are classified into three risk categories:
              <ul>
                <li>Class I: Low-risk devices that require simple registration and self-declaration.</li>
                <li>Class II: Moderate-risk devices that require comprehensive technical documentation and conformity assessment.</li>
                <li>Class III: High-risk devices that require rigorous evaluation, including clinical trials and detailed technical documentation.</li>
              </ul>
            </li>

            <li>
              <strong>NMPA Registration Process:</strong>
              <ol>
                <li>
                  <strong>Determining Classification:</strong> Determine the appropriate classification of the device based on the NMPA’s classification catalog.
                </li>
                <li>
                  <strong>Preparation of Technical Documentation:</strong>
                  <ul>
                    <li>Product Description: Detailed description of the device, including design, intended use, and labeling.</li>
                    <li>Quality Management System (QMS): Documentation demonstrating compliance with ISO 13485 or equivalent standards.</li>
                    <li>Risk Analysis: Comprehensive risk management report in accordance with ISO 14971.</li>
                    <li>Preclinical and Clinical Data: Test reports, clinical evaluation reports, and clinical trial data (if applicable).</li>
                    <li>Standards Compliance: Evidence of compliance with applicable Chinese standards (GB/T standards).</li>
                  </ul>
                </li>
                <li>
                  <strong>Local Testing:</strong> Conduct testing at an NMPA-accredited testing laboratory in China, including electrical safety, biocompatibility, and performance testing.
                </li>
                <li>
                  <strong>Clinical Evaluation:</strong>
                  <ul>
                    <li>For Class II and III devices, clinical trials may be required in China.</li>
                    <li>Clinical Evaluation Report (CER): For some devices, a CER based on existing clinical data may suffice.</li>
                  </ul>
                </li>
                <li>
                  <strong>Submission and Review:</strong>
                  <ul>
                    <li>Dossier Submission: Submit the complete registration dossier to the NMPA.</li>
                    <li>Technical Review: NMPA conducts a thorough technical review of the submitted documents.</li>
                    <li>Administrative Review: Review of administrative compliance and documentation.</li>
                  </ul>
                </li>
                <li>
                  <strong>Approval and Certification:</strong>
                  <ul>
                    <li>Certificate Issuance: Upon successful review, the NMPA issues a registration certificate, valid for five years.</li>
                    <li>Post-Market Surveillance: Maintain compliance through regular post-market surveillance and reporting adverse events.</li>
                  </ul>
                </li>
              </ol>
            </li>
          </ul>

          <p className="mb-30">
            <strong>SQUARE MEDTECH Expertise:</strong> The SQUARE MEDTECH team offers extensive experience and expertise in navigating the NMPA registration process for medical devices, enabling manufacturers to successfully enter and compete in the Chinese market.
          </p>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Our Expertise in NMPA Registration
                </h4>
                <p>
                  At <strong>SQUARE MEDTECH</strong>, our expertise ensures smooth navigation through the NMPA registration process, providing manufacturers with the guidance needed to enter the Chinese market successfully.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Why Choose Us for NMPA Registration
                </h4>
                <p>
                  We combine unparalleled regulatory knowledge and expertise in medical device registration to ensure that your products meet all requirements for the Chinese market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Evaluation</li>
                        <li>Clinical Investigation </li>
                        <li>Regulatory Life Cycle</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>PMCF Solution</li>
                        <li>Healthcare Survey</li>
                        <li>  EU MDR/FDA/ NMPA Consulting</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>


          {/* Updated section for ROA Registration */}
          <h3 className="title-animation mb-15">
            iv) ROA Registration
          </h3>
          <p className="mb-30">
            Medical devices must be registered individually in each country
            outside of the EU, as each country has its own regulations governing
            medical devices. Having an EU CE mark or FDA clearance/approval can
            often simplify these registrations and, in some cases, make the process
            quite straightforward. Typically, each country requires a local presence,
            such as an office or an agent, to facilitate the registration process.
          </p>
          <p className="mb-30">
            <strong>SQUARE MEDTECH Expertise</strong>
            <br />
            The <strong>SQUARE MEDTECH</strong> team has in-depth knowledge and
            experience with the ROA, assisting medical device manufacturers in
            achieving regulatory compliance and gaining market access efficiently.
          </p>

          <h3 className="title-animation mb-15">
            How SQUARE MEDTECH Can Support in Digital Health Services:
          </h3>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Regulatory Compliance Expertise</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Quality Management System (QMS) Integration</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Cybersecurity and Software Documentation</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>
                Digital health projects compliant with current regulations
              </h5>
            </li>
          </ul>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Our Expertise in Digital Health
                </h4>
                <p>
                  At <strong>SQUARE MEDTECH</strong>, our expertise in digital
                  health solutions ensures smooth project management,
                  regulatory compliance, and optimized performance for all
                  medical device developers.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Why Choose Us for Digital Health
                </h4>
                <p>
                  We bring together unparalleled regulatory knowledge and
                  software development skills to provide a comprehensive
                  approach to digital health projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Post Market Activities</li>
                        <li> Technical Documentation </li>
                        <li>Quality Management System</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>QA / RA Service</li>
                        <li>Product Registrations </li>
                        <li>   Digital Health Services</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}
