import React from 'react'
import { Link } from 'react-router-dom'

export const HealthcareInner = () => {
  return (
    <>
        <>
        <section class="service-details section-space">
            <div class="container">
                <div class="row flex-xl-row flex-column-reverse">
                    <div class="col-xl-4">
                        <div class="sidebar sidebar-rr-sticky">
                            <div class="sidebar__widget-service mb-30">
                            <Link to="/service">Medical Device Manufacturers <i class="fa-solid fa-plus"></i> </Link>
                            <Link to="/pharmaceutical-details">Pharmaceutical & Biotech industry<i class="fa-solid fa-plus"></i> </Link>
                            <Link to="/healthcare-details">Healthcare Providers <i class="fa-solid fa-plus"></i> </Link>

                            </div>

                            <div class="sidebar__widget-contact__service text-center">
                                <h4 class="mb-25 title-animation text-capitalize">Are You ready to contact with us</h4>
                                <p class="mb-20"><a href="tel:+86 19811976744"><i class="fa-solid fa-phone"></i>(+86) 19811976744</a></p>

                                <Link to="/contact-us#" class="rr-btn">
                                    <span class="btn-wrap">
                                        <span class="text-one">Contact Us <i class="fa-solid fa-plus"></i></span>
                                        <span class="text-two">Contact Us <i class="fa-solid fa-plus"></i></span>
                                    </span>
                                </Link>
                </div>
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="service-details__content">
                            <div class="service-details__content-media mb-20">
                                <img src="./assets/imgs/service-details/service-details-1.jpg" class="img-fluid" alt="img not found"/>
                            </div>

                            <h2 class="title-animation mb-20">Healthcare Providers </h2>

                            <p class="mb-20">SQUARE MEDTECH team is a mixture of trained healthcare professionals with Cardiovascular Medicine (Cath-Lab specialty), Ultra-sonography, Dentistry, Orthopedic Surgery, General Practitioners, Nurse, multidisciplinary clinical specialists and with Engineering professional backgrounds for MedTech development. Our team of experienced professionals brings together a wealth of knowledge and expertise in clinical research, regulatory affairs, data management, and patient care. </p>
                            <h3 class="title-animation mb-10">How SQUARE MEDTECH Can Support in Healthcare Providers</h3>

                            <p class="mb-30">At SQUARE MEDTECH, we offer specialized support to healthcare providers with a focus on research, regulatory compliance, and data management solutions, ensuring tailored services for every client’s unique needs.</p>

                            <ul class="mb-40">
                                <li>
                                    <span><i class="fa-solid fa-check"></i></span>
                                    <h5>Healthcare Research & Development Consultancy</h5>
                                </li>
                                <li>
                                    <span><i class="fa-solid fa-check"></i></span>
                                    <h5>Customized Solutions for Clinical Research Consultancy</h5>
                                </li>
                                <li>
                                    <span><i class="fa-solid fa-check"></i></span>
                                    <h5>Regulatory Consultancy for Local & Global Standards</h5>
                                </li>
                                <li>
                                    <span><i class="fa-solid fa-check"></i></span>
                                    <h5>Streamline Data Management Consultancy</h5>
                                </li>
                            </ul>
                            <h3 class="title-animation mb-10">Healthcare Consultancy</h3>
                                <p class="mb-30">SQUARE MEDTECH Healthcare Consultancy delivers clinical expert advice from multidisciplinary clinical specialists on optimizing clinical operations and enhancing patient outcomes. We support healthcare providers with trained healthcare professionals to streamline processes and improve efficiency across healthcare research & development initiatives.</p>

                                <h3 class="title-animation mb-10">Clinical Research Consultancy</h3>
                                <p class="mb-30">SQUARE MEDTECH Clinical Research Consultancy offers expert help in designing and managing clinical trials, ensuring compliance with regulations. We provide customized solutions for planning, conducting, and managing data to improve trial results and simplify regulatory submissions. Our goal is to make the process more efficient and speed up the time to market for new treatments.</p>

                                <h3 class="title-animation mb-10">Regulatory Consultancy</h3>
                                <p class="mb-30">SQUARE MEDTECH Regulatory Consultancy provides expert advice to help you navigate regulatory requirements and ensure compliance with both local and global standards. We assist with submissions, approvals, and ongoing compliance for medical devices and pharmaceuticals, aiming to make market entry smooth and keep up with changing regulations.</p>


                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="service-details__content-media-item service-details__content-media-item-1">
                                        <div class="service-details__content-media-item_img mb-10">
                                            <img src="./assets/imgs/service-details/service-details-2.jpg" class="img-fluid" alt="img not found"/>
                                        </div>
                                        <h4 class="title-animation mb-15">Data Management Consultancy</h4>
                                        <p>SQUARE MEDTECH Data Management Consultancy provides expert guidance on optimizing data collection, integration, and analysis processes to ensure accurate, compliant, and actionable insights. We streamline data management systems to enhance efficiency and support informed decision-making throughout your research.</p>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="service-details__content-media-item service-details__content-media-item-2">
                                        <div class="service-details__content-media-item_img mb-10">
                                            <img src="./assets/imgs/service-details/service-details-3.jpg" class="img-fluid" alt="img not found"/>
                                        </div>
                                        <h4 class="title-animation mb-15">Our Expertise in Healthcare Providers</h4>
                                        <p>At SQUARE MEDTECH, we specialize in assisting Healthcare Providers to navigate the complexities. Our dedicated team for healthcare professional solutions combines regulatory expertise and specialized skills for healthcare providers under one roof, ensuring seamless project support.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

</>
    </>
  )
}
