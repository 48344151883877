import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain';
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../../Compoents/FooterWhite';
import { Link } from 'react-router-dom';

export const DigitalHealthSolutions = () => {
    const breadcrumbLinks = [       
        { path: '/', label: 'Home' },
        { label: 'Digital Health Solutions', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Digital Health Solutions" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

          <h2 className="title-animation mb-20">Digital Health Solutions</h2>

          <p className="mb-20">
            The increasing prevalence of software as a medical device (SaMD) or
            as part of a device, often incorporating AI, IoT, mobile apps, or
            telemedicine, is accompanied by evolving regulatory requirements and
            heightened information security concerns. These challenges
            necessitate a comprehensive approach to compliance and development.
          </p>

          <h3 className="title-animation mb-15">
            The Digital Health Solutions Include:
          </h3>
          <ul className="mb-30">
            <li>
              <strong>Development Processes:</strong> Insight into the unique
              aspects of developing medical software.
            </li>
            <li>
              <strong>Regulatory Landscape:</strong> Medical software that meets
              the regulatory requirements for health data management.
            </li>
            <li>
              <strong>Technical Tools:</strong> The necessary tools and
              technologies to develop and secure digital health solutions.
            </li>
          </ul>

          <p className="mb-30">
            Our integrated approach ensures that your digital health projects
            are not only compliant with current regulations but also optimized
            for performance and security.
          </p>

          <h3 className="title-animation mb-15">
            Our Expertise in Digital Health Solutions
          </h3>
          <p className="mb-30">
            At <strong>SQUARE MEDTECH</strong>, we specialize in assisting
            medical device developers to navigate the complexities. Our
            dedicated team for digital health solutions combines regulatory
            expertise and software development skills under one roof, ensuring
            seamless project support.
          </p>

          <h3 className="title-animation mb-15">
            How SQUARE MEDTECH Can Support in Digital Health Services:
          </h3>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Regulatory Compliance Expertise</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Quality Management System (QMS) Integration</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Cybersecurity and Software Documentation</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>
                Digital health projects compliant with current regulations
              </h5>
            </li>
          </ul>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Our Expertise in Digital Health
                </h4>
                <p>
                  At <strong>SQUARE MEDTECH</strong>, our expertise in digital
                  health solutions ensures smooth project management,
                  regulatory compliance, and optimized performance for all
                  medical device developers.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Why Choose Us for Digital Health
                </h4>
                <p>
                  We bring together unparalleled regulatory knowledge and
                  software development skills to provide a comprehensive
                  approach to digital health projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Solutions for Healthcare Providers</h4>
                        <ul  class="color-white mb-0">
                        <li>Consultancy for Healthcare Startups</li>
                        <li>Consultation for Hospitals and Clinics</li>
                        <li>Consultation for Healthcare, Pharma & Biotech Industries</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Solutions for Healthcare Providers</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Research Consultancy</li>
                        <li>Global & Local Regulatory Consultancy</li>
                        <li>Data Management Guidance Consultancy</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}
