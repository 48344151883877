import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain';
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../../Compoents/FooterWhite';
import { Link } from 'react-router-dom';

export const TechnicalDocument = () => {
    const breadcrumbLinks = [       
        { path: '/', label: 'Home' },
        { label: 'Technical Document Update  Details', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Technical Document Update  Details" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

          <h2 className="title-animation mb-20">Technical Document Update</h2>

          <p className="mb-20">
            Technical documentation is essential for medical devices to ensure compliance with regulatory standards and market approval. Below is a breakdown of the requirements, specifically for the European MDR and other global regulations.
          </p>

          <h3 className="title-animation mb-15">European MDR Requirements</h3>
          <ul className="mb-30">
            <li>
              <strong>Device Description and Specifications:</strong> Detailed information about the medical device, including its intended purpose, design, and technical specifications.
            </li>
            <li>
              <strong>Manufacturing Information:</strong> Comprehensive description of the manufacturing processes, materials used, and quality control measures in place.
            </li>
            <li>
              <strong>Design and Manufacturing Changes:</strong> Documentation of any changes to the device design or manufacturing processes.
            </li>
            <li>
              <strong>General Safety and Performance Requirements (GSPRs):</strong> Evidence demonstrating how the device meets GSPRs, including risk management and clinical evaluation.
            </li>
            <li>
              <strong>Risk Management:</strong> A complete risk management plan, evaluating and mitigating potential risks.
            </li>
            <li>
              <strong>Usability:</strong> Ensuring the device is usable effectively and safely.
            </li>
            <li>
              <strong>Software:</strong> Adhering to the IEC 62304 standard for software as a medical device.
            </li>
            <li>
              <strong>Medical Electric Safety:</strong> Compliance with IEC 60601-1 for safe operation of electrical medical devices.
            </li>
            <li>
              <strong>Clinical Evaluation:</strong> Clinical data supporting safety and performance claims.
            </li>
            <li>
              <strong>Clinical Investigation:</strong> Clinical investigations to prove safety and performance.
            </li>
            <li>
              <strong>Post-Market Surveillance (PMS):</strong> Monitoring the device’s performance after it enters the market.
            </li>
          </ul>

          <h3 className="title-animation mb-15">Detailed Requirements from Standards and Guidance Documents</h3>
          <ul className="mb-30">
            <li>
              <strong>ISO 13485:</strong> Specifies requirements for a comprehensive quality management system for medical device design and manufacture.
            </li>
            <li>
              <strong>MDCG Guidance Documents:</strong> Provides specific guidance on regulatory compliance, including cyber security for medical devices.
            </li>
          </ul>

          <h3 className="title-animation mb-15">SQUARE MEDTECH Expertise</h3>
          <p className="mb-30">
            With SQUARE MEDTECH’s expertise, you can ensure your technical documentation is up-to-date and compliant, supporting your product’s market success and patient safety.
          </p>

          <h3 className="title-animation mb-15">How SQUARE MEDTECH Can Support in Technical Document Update</h3>
          <ul className="mb-40">
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Regulatory Compliance Expertise</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>GAP Analysis and Documentation Review</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Documentation Preparation and Update</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Quality Management System (QMS) Integration</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Training and Support</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Cybersecurity and Software Documentation</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Post-Market Surveillance (PMS) and Vigilance Reporting</h5>
            </li>
            <li>
              <span><i className="fa-solid fa-check"></i></span>
              <h5>Submission and Liaison with Regulatory Bodies</h5>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</section>


    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Evaluation</li>
                        <li> Clinical Investigation </li>
                        <li> Regulatory Life Cycle</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li> PMCF Solution</li>
                        <li>Healthcare Survey</li>
                        <li> EU MDR/FDA/ NMPA Consulting</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

         

          <h3 className="title-animation mb-15">
           b) United States FDA Requirements
          </h3>
          <p className="mb-30">
            The FDA provides detailed documentation requirements through its regulations, such as 21 CFR Part 820 for Quality System Regulation (QSR), and guidance documents like the FDA's "Guidance for Industry and FDA Staff: Content of Premarket Submissions for Management of Cybersecurity in Medical Devices."
          </p>

          <h3 className="title-animation mb-15">
            SQUARE MEDTECH Expertise
          </h3>
          <p className="mb-30">
            By leveraging SQUARE MEDTECH’s expertise with comprehensive solutions and resources, you can ensure that your technical documentation is always up-to-date, compliant, and ready for regulatory submissions, ultimately supporting your product’s market success and patient safety.
          </p>

          <h3 className="title-animation mb-15">
            How SQUARE MEDTECH Can Support in Technical Document Update:
          </h3>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Regulatory Compliance Expertise</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>GAP Analysis and Documentation Review</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Documentation Preparation and Update</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Quality Management System (QMS) Integration</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Training and Support</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Cybersecurity and Software Documentation</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Post-Market Surveillance (PMS) and Vigilance Reporting</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Submission and Liaison with Regulatory Bodies</h5>
            </li>
          </ul>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Our Expertise in Digital Health
                </h4>
                <p>
                  At <strong>SQUARE MEDTECH</strong>, our expertise in digital
                  health solutions ensures smooth project management,
                  regulatory compliance, and optimized performance for all
                  medical device developers.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Why Choose Us for Digital Health
                </h4>
                <p>
                  We bring together unparalleled regulatory knowledge and
                  software development skills to provide a comprehensive
                  approach to digital health projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Post Market Activities</li>
                        <li>Technical Documentation </li>
                        <li>Quality Management System</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>  QA / RA Service</li>
                        <li>Product Registrations </li>
                        <li>Digital Health Services</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          <div className="service-details__content-media mb-20">
            <img
              src="./assets/imgs/service-details/service-details-1.jpg"
              className="img-fluid"
              alt="img not found"
            />
          </div>

          {/* New Section for China (NMPA) Requirements */}
          <h3 className="title-animation mb-15">
           c) China (NMPA) Requirements
          </h3>
          <p className="mb-20">
            The National Medical Products Administration (NMPA) outlines documentation requirements in its regulations and standards, such as the “Regulations for the Supervision and Administration of Medical Devices” and specific guidelines for technical review documentation.
          </p>

          <h3 className="title-animation mb-15">
            SQUARE MEDTECH Expertise
          </h3>
          <p className="mb-30">
            By leveraging SQUARE MEDTECH’s expertise with comprehensive solutions and resources, you can ensure that your technical documentation is always up-to-date, compliant, and ready for regulatory submissions, ultimately supporting your product’s market success and patient safety.
          </p>

          <h3 className="title-animation mb-15">
            How SQUARE MEDTECH Can Support in Technical Document Update:
          </h3>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Regulatory Compliance Expertise</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>GAP Analysis and Documentation Review</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Documentation Preparation and Update</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Quality Management System (QMS) Integration</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Training and Support</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Cybersecurity and Software Documentation</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Post-Market Surveillance (PMS) and Vigilance Reporting</h5>
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              <h5>Submission and Liaison with Regulatory Bodies</h5>
            </li>
          </ul>

          <div className="row">
            <div className="col-lg-7">
              <div className="service-details__content-media-item service-details__content-media-item-1">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-2.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Our Expertise in Digital Health
                </h4>
                <p>
                  At <strong>SQUARE MEDTECH</strong>, our expertise in digital
                  health solutions ensures smooth project management,
                  regulatory compliance, and optimized performance for all
                  medical device developers.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-details__content-media-item service-details__content-media-item-2">
                <div className="service-details__content-media-item_img mb-10">
                  <img
                    src="./assets/imgs/service-details/service-details-3.jpg"
                    className="img-fluid"
                    alt="img not found"
                  />
                </div>
                <h4 className="title-animation mb-15">
                  Why Choose Us for Digital Health
                </h4>
                <p>
                  We bring together unparalleled regulatory knowledge and
                  software development skills to provide a comprehensive
                  approach to digital health projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Evaluation</li>
                        <li> Clinical Investigation </li>
                        <li>Regulatory Life Cycle</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li> PMCF Solution</li>
                        <li>Healthcare Survey</li>
                        <li>EU MDR/FDA/ NMPA Consulting</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li>Post Market Activities</li>
                        <li>Technical Documentation </li>
                        <li>Quality Management System</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Service for Product Registration</h4>
                        <ul  class="color-white mb-0">
                        <li> PMCF Solution</li>
                        <li>Healthcare Survey</li>
                        <li>EU MDR/FDA/ NMPA Consulting</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}
