import React from 'react'
import HeaderMain from '../../Compoents/HeaderMain';
import BreadcrumbArea from '../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../Compoents/FooterWhite';
import { PharmaceuticalMainSection } from './InnerSection/PharmaceuticalMainSection';

export const PharmaceuticalDetails = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: 'Pharmaceutical & Biotech Industry', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Pharmaceutical & Biotech Industry" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
       <PharmaceuticalMainSection/>
    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Pharma & Biotech Solutions</h4>
                        <ul  class="color-white mb-0">
                          <li>Clinical Investigation Planning</li>
                          <li>Clinical Research Sites Network</li>
                          <li>Complete Project Management Solution</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Pharma & Biotech Solutions</h4>
                        <ul  class="color-white mb-0">
                        <li>Non-commercial Clinical Investigations</li>
                        <li>Medical Writing Protocol Development</li>
                        <li>Comprehensive Pharmacovigilance Suite</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}
