import React from 'react'
import HeaderMain from '../../Compoents/HeaderMain';
import BreadcrumbArea from '../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../Compoents/FooterWhite';
import { PharmaceuticalMainSection } from './InnerSection/PharmaceuticalMainSection';
import { HealthcareInner } from './InnerSection/HealthcareInner';

export const HealthcareDetails = () => {
    const breadcrumbLinks = [
        { path: '/', label: 'Home' },
        { label: 'Healthcare Providers', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Healthcare Providers" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
       <HealthcareInner/>
    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Solutions for Healthcare Providers</h4>
                        <ul  class="color-white mb-0">
                        <li>Consultancy for Healthcare Startups</li>
                        <li>Consultation for Hospitals and Clinics</li>
                        <li>Consultation for Healthcare, Pharma & Biotech Industries</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Solutions for Healthcare Providers</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Research Consultancy</li>
                        <li>Global & Local Regulatory Consultancy</li>
                        <li>Data Management Guidance Consultancy</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}
