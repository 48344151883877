import React from 'react'
import HeaderMain from '../../../Compoents/HeaderMain';
import BreadcrumbArea from '../../../ShearOne/BreadcrumbArea';
import FooterWhite from '../../../Compoents/FooterWhite';
import { Link } from 'react-router-dom';

export const NotifyBodyB = () => {
    const breadcrumbLinks = [       
        { path: '/', label: 'Home' },
        { label: 'Notified Body Transition Details', active: true }
      ];
      return (
        <>
        <HeaderMain/>
        <main>
       
       <BreadcrumbArea 
         title="Notified Body Transition Details" 
         backgroundImage="/assets/imgs/breadcrumb/page-header-1.png" 
         links={breadcrumbLinks} 
       />
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You ready to contact with us
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          {/* Existing content */}
          <h3 className="title-animation mb-15">
            Fill up the Gap by Medical Writing
          </h3>
          <p>
            We support you in medical writing all documents required in a clinical evaluation process or clinical investigations. Our experts utilize templates and forms to ensure the completeness and regulatory compliance of these numerous documents.
          </p>

          <h4 className="title-animation mb-15">i) Clinical Evaluation</h4>
          <p>
            SQUARE MEDTECH assists you in developing the necessary clinical evidence for your medical device, ensuring alignment with your intended purpose, claims, and technical documentation with proper planning, preparation, and conduct of perfect clinical evaluations through the following services.
          </p>
          <ul className="mb-30">
            <li>
              <strong>Clinical Data Strategy:</strong> SQUARE MEDTECH assists you in planning and maintaining an efficient clinical data strategy from product development through to the post-market phase. A well-planned strategy can expedite your device's market entry and bolster your marketing efforts. Our goal in developing a clinical data strategy is to ensure that investigations provide the necessary clinical evidence to support your claims, while also optimizing the use of your resources.
            </li>
            <li>
              <strong>Clinical Evaluation Plan & Report:</strong> SQUARE MEDTECH team can help you develop and update your clinical evaluation documentation to ensure they fulfill regulatory criteria. Clinical assessment is an essential link between the technical file and clinical investigation. It is a systematic and continuing procedure that validates the device's safety and performance, including clinical benefits.
            </li>
            <li>
              <strong>Clinical Evaluation SOP:</strong> SQUARE MEDTECH team can support you in creating Standard Operating Procedures (SOPs). SOP is a detailed document outlining the processes and procedures involved in conducting a clinical evaluation of a medical device. The SOP ensures that clinical evaluations are performed consistently, thoroughly, and in compliance with regulatory requirements. Our efforts can save your time by ensuring the right fit for your device.
            </li>
          </ul>

          <h4 className="title-animation mb-15">How SQUARE MEDTECH can help in Medical Writing?</h4>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Support for necessary Clinical Evidence
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Regulatory Documents for Clinical Evaluations
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Literature Search
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Statistical Evaluations
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
  <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Clinical Evaluation Services</h4>
                        <ul  class="color-white mb-0">
                        <li>Support for Safety & Performance</li>
                        <li>Develop and update CE documentations</li>
                        <li> Alignment with Intended purpose, Claims &     Technical Document</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Clinical Evaluation Services</h4>
                        <ul  class="color-white mb-0">
                        <li>Clinical Evidence support</li>
                        <li> Efficient clinical data strategy</li>
                        <li>Standard Operating Procedure</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="service-details section-space">
  <div className="container">
    <div className="row flex-xl-row flex-column-reverse">
      <div className="col-xl-4">
        <div className="sidebar sidebar-rr-sticky">
          <div className="sidebar__widget-service mb-30">
            <Link to="/service">
              Medical Device Manufacturers <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/pharmaceutical-details">
              Pharmaceutical & Biotech Industry <i className="fa-solid fa-plus"></i>
            </Link>
            <Link to="/healthcare-details">
              Healthcare Providers <i className="fa-solid fa-plus"></i>
            </Link>
          </div>

          <div className="sidebar__widget-contact__service text-center">
            <h4 className="mb-25 title-animation text-capitalize">
              Are You Ready to Contact With Us?
            </h4>
            <p className="mb-20">
              <a href="tel:+86 19811976744">
                <i className="fa-solid fa-phone"></i>(+86) 19811976744
              </a>
            </p>

            <Link to="/contact-us#" className="rr-btn">
              <span className="btn-wrap">
                <span className="text-one">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
                <span className="text-two">
                  Contact Us <i className="fa-solid fa-plus"></i>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="service-details__content">
          {/* Existing content */}
          <h3 className="title-animation mb-15">ii) Clinical Investigation</h3>
          <p>
            Clinical investigations are the most dependable approach to provide high-quality data concerning the safety & performance of a medical device. SQUARE MEDTECH offers assistance and experience in planning, preparation, and execution of clinical investigations considering NB requirements.
          </p>

          <h4 className="title-animation mb-15">Clinical Investigation Planning</h4>
          <p>
            Starting early in planning the collection of clinical evidence during product development is essential for timely market entry. We assist our clients in aligning clinical development planning with regulatory requirements, business strategies, and budgets. Our team will be assembled to design and develop your clinical investigation, ensuring seamless collaboration with our medical device team and consultants to support you for optimal results.
          </p>

          <h4 className="title-animation mb-15">Clinical Investigation Project Management</h4>
          <p>
            We collaborate closely with you to efficiently lead your clinical investigation project, saving both time and resources. Our team, comprising experienced project managers, monitors, medical writers, data managers, and medical advisors, offers a range of services according to your needs. Whether you require specific services or a complete solution, we are dedicated to supporting your project with the expertise and resources necessary to ensure success.
          </p>

          <h4 className="title-animation mb-15">Regulatory Submissions</h4>
          <p>
            We possess in-depth knowledge of the often intricate local and EU MDR, as well as FDA regulations and requirements for clinical investigations. Regardless of the regulatory objectives of your clinical investigation, we ensure our clients remain compliant and maintain excellent communication with ethical committees and regulatory authorities.
          </p>
          <p>
            Our extensive experience in preparing regulatory documents consistently results in efficient and successful outcomes. We guarantee that safety reporting, a critical component of any clinical investigation, is conducted meticulously in accordance with all applicable requirements.
          </p>

          <h4 className="title-animation mb-15">
            How SQUARE MEDTECH Can Help in Data & Investigation Site Management?
          </h4>
          <ul className="mb-40">
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Biostatistics
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Data Protection
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              General Data Management
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Site Selection
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Site Monitoring
            </li>
            <li>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              Site Management
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
    </main>


    <section class="footer__cta footer__cta-bottom-up">
    <div class="container">
        <div class="row mb-minus-30">
            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-primary d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-1.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Medical Writing Services</h4>
                        <ul  class="color-white mb-0">
                        <li>Investigator site file</li>
                        <li>CRFs & Questionnaires</li>
                        <li> Clinical investigation plan </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="footer__cta-item mb-30 theme-bg-glow d-flex flex-lg-row flex-column align-items-lg-center">
                    <div class="footer__cta-item-icon d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="/assets/imgs/footer__cta/footer__cta-item-2.png" alt="icon not found"/>
                    </div>

                    <div class="footer__cta-item-text">
                        <h4 class="color-white mb-15 mb-10">Our Medical Writing Services</h4>
                        <ul  class="color-white mb-0">
                        <li>Safety documents</li>
                        <li>Registration of clinical studies</li>
                        <li> Literature search & Publications</li>
                      </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <FooterWhite/>

    </>
  )
}
