import React from 'react'
import { Link } from 'react-router-dom'

export const PharmaceuticalMainSection = () => {
  return (
<>
<section class="service-details section-space">
    <div class="container">
        <div class="row flex-xl-row flex-column-reverse">
            <div class="col-xl-4">
                <div class="sidebar sidebar-rr-sticky">
                    <div class="sidebar__widget-service mb-30">
                     <Link to="/service">Medical Device Manufacturers <i class="fa-solid fa-plus"></i> </Link>
                     <Link to="/pharmaceutical-details">Pharmaceutical & Biotech industry<i class="fa-solid fa-plus"></i> </Link>
                     <Link to="/healthcare-details">Healthcare Providers <i class="fa-solid fa-plus"></i> </Link>

                    </div>

                    <div class="sidebar__widget-contact__service text-center">
                        <h4 class="mb-25 title-animation text-capitalize">Are You ready to contact with us</h4>
                        <p class="mb-20"><a href="tel:+86 19811976744"><i class="fa-solid fa-phone"></i>(+86) 19811976744</a></p>

                        <Link to="/contact-us#" class="rr-btn">
                            <span class="btn-wrap">
                                <span class="text-one">Contact Us <i class="fa-solid fa-plus"></i></span>
                                <span class="text-two">Contact Us <i class="fa-solid fa-plus"></i></span>
                            </span>
                        </Link>
        </div>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="service-details__content">
                    <div class="service-details__content-media mb-20">
                        <img src="./assets/imgs/service-details/service-details-1.jpg" class="img-fluid" alt="img not found"/>
                    </div>

                    <h2 class="title-animation mb-20">Pharmaceutical & Biotech industry </h2>

                    <p class="mb-20">Our team members with expertise in CROs and Pharmaceutical & Biotech Industry
                    work together to provide the most effective solutions for you. With years of industry expertise, our team of multidisciplinary specialists is well-equipped to deliver excellent regulatory and high-quality services in a timely manner. Whether it's a one-time task or a long-term partnership, we customize our approach to your specific needs and accompany you through every step of your regulatory journey to help you meet your business objectives.

                    </p>
                

                    <h3>How SQUARE MEDTECH Can Support in Pharmaceutical & Biotech Industry:</h3>
                        <ul class="mb-40">
                            <li>
                                <span><i class="fa-solid fa-check"></i></span>
                                <h5>Clinical Investigation Strategy</h5>
                            </li>
                            <li>
                                <span><i class="fa-solid fa-check"></i></span>
                                <h5>Complete solution of Clinical Investigation Project Management</h5>
                            </li>
                            <li>
                                <span><i class="fa-solid fa-check"></i></span>
                                <h5>Regulatory Submissions</h5>
                            </li>
                            <li>
                                <span><i class="fa-solid fa-check"></i></span>
                                <h5>Comprehensive range of Data Management</h5>
                            </li>
                            <li>
                                <span><i class="fa-solid fa-check"></i></span>
                                <h5>Medical Writing Protocol Development</h5>
                            </li>
                            <li>
                                <span><i class="fa-solid fa-check"></i></span>
                                <h5>Comprehensive suite of Pharmacovigilance</h5>
                            </li>
                        </ul>


                        <h3 class="title-animation mb-10">Clinical Investigation Planning</h3>
                        <p class="mb-30">Starting early in planning the collection of clinical evidence during product development is essential for timely market entry. We assist our clients in aligning clinical development planning with regulatory requirements, business strategies, and budgets. Our team will be assembled to design and develop your clinical investigation, ensuring seamless collaboration with our medical device team and consultants to support optimal results.</p>

                        <h3 class="title-animation mb-10">Clinical Investigation Project Management</h3>
                        <p class="mb-30">SQUARE MEDTECH collaborates closely with you to efficiently lead your clinical investigation project, saving both time and resources. Our team, comprising experienced project managers, monitors, medical writers, data managers, and medical advisors, offers a range of services according to your needs. Whether you require specific services or a complete solution, we are dedicated to supporting your project with the expertise and resources necessary to ensure success.</p>

                        <h3 class="title-animation mb-10">Regulatory Submissions</h3>
                        <p class="mb-30">SQUARE MEDTECH possesses in-depth knowledge of the often intricate local and EU MDR, as well as FDA regulations and requirements for clinical investigations. Regardless of the regulatory objectives of your clinical investigation, we ensure our clients remain compliant and maintain excellent communication with ethical committees and regulatory authorities.</p>

                        <h3 class="title-animation mb-10">Data Management</h3>
                        <p class="mb-30">SQUARE MEDTECH provides a comprehensive range of data management services, including the design, setup, and maintenance of electronic Case Report Forms (eCRFs).</p>

                        <h3 class="title-animation mb-10">Pharmacovigilance</h3>
                        <p class="mb-30">In partnership with leading vendors, SQUARE MEDTECH offers a comprehensive suite of pharmacovigilance services. These include developing safety management plans, conducting thorough safety reviews, and submitting safety reports to relevant regulatory authorities in compliance with Good Pharmacovigilance Practices.</p>

                        <h3 class="title-animation mb-10">Medical Writing</h3>
                        <p class="mb-30">SQUARE MEDTECH team provides comprehensive and reliable medical writing support to accelerate submissions and approvals. Integrated with our clinical development expertise, our medical writing services encompass protocol development, informed consent forms (ICFs), investigator brochures, clinical study reports, regulatory writing, and safety documents.</p>

                        <h3 class="title-animation mb-10">Non-commercial Clinical Trials</h3>
                        <p class="mb-30">SQUARE MEDTECH team assists in the organization and execution of non-commercial clinical research, including academic trials.</p>


                    <div class="row">
                        <div class="col-lg-7">
                            <div class="service-details__content-media-item service-details__content-media-item-1">
                                <div class="service-details__content-media-item_img mb-10">
                                    <img src="./assets/imgs/service-details/service-details-2.jpg" class="img-fluid" alt="img not found"/>
                                </div>
                                <h4 class="title-animation mb-15">Clinical Research Sites Network</h4>
                                <p>SQUARE MEDTECH anticipates that our partnership will open new avenues for healthcare advancement. Our expertise in coordinating clinical trials at the site level gives our partners access to a vast network of sites. We offer streamlined solutions to enhance the efficiency of clinical trial management through a unified and simplified approach. </p>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="service-details__content-media-item service-details__content-media-item-2">
                                <div class="service-details__content-media-item_img mb-10">
                                    <img src="./assets/imgs/service-details/service-details-3.jpg" class="img-fluid" alt="img not found"/>
                                </div>
                                <h4 class="title-animation mb-15">Our Expertise in Pharma & Biotech Solutions</h4>
                                <p>At SQUARE MEDTECH, we specialize in assisting Pharma & Biotech industry developers to navigate the complexities. Our dedicated team for Pharma & Biotech solutions combines regulatory expertise and Pharma & Biotech skills under one roof, ensuring seamless project support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

</>
  )
}
