import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay, Pagination } from 'swiper/modules';

const TestimonialSlider = () => {
    return (
        <div className="swiper-container">
            <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{ delay: 6000  }} // Autoplay with a 3-second delay
                pagination={{ clickable: true }}
                centeredSlides={true} // Center the active slide
                className="swiper-wrapper client-testimonial__slider-wrapper"
            >
                <SwiperSlide className="swiper-slide">
                    <div className="client-testimonial__item">
                        <div className="client-testimonial__item-icon mb-30 mb-xs-25">
                            <img className="img-fluid" src="assets/imgs/client-testimonial/qoute.png" alt="icon not found" />
                        </div>
                        <p className="mb-30 mb-xs-25">
                        <b>What services does SQUARE MEDTECH offer?  </b><br/>

                            Our services cover Clinical Evaluation, Investigation, Pre- and Post-Market Surveillance, Technical Documentation, Medical Writing, Study Design, Regulatory Consulting, Data Management, Statistical Analysis, and healthcare surveys.

                        </p>
                       
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="client-testimonial__item">
                        <div className="client-testimonial__item-icon mb-30 mb-xs-25">
                            <img className="img-fluid" src="assets/imgs/client-testimonial/qoute.png" alt="icon not found" />
                        </div>
                        <p className="mb-30 mb-xs-25">
                        <b>Do you specialize in any particular therapeutic areas?  </b><br/>

                        We specialize in diverse therapeutic areas such as Cardiovascular, Medical Imaging, Oral Care, Orthopedics, Respiratory, Oncology, Neurology, Nephrology, Urology, and Pharmaceuticals & Biotech products.
                        </p>
                     
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="client-testimonial__item">
                        <div className="client-testimonial__item-icon mb-30 mb-xs-25">
                            <img className="img-fluid" src="assets/imgs/client-testimonial/qoute.png" alt="icon not found" />
                        </div>
                        <p className="mb-30 mb-xs-25">
                        <b>Do you specialize in any particular therapeutic areas?  </b><br/>

                        We specialize in diverse therapeutic areas such as Cardiovascular, Medical Imaging, Oral Care, Orthopedics, Respiratory, Oncology, Neurology, Nephrology, Urology, and Pharmaceuticals & Biotech products.
                        </p>
                       
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="client-testimonial__item">
                        <div className="client-testimonial__item-icon mb-30 mb-xs-25">
                            <img className="img-fluid" src="assets/imgs/client-testimonial/qoute.png" alt="icon not found" />
                        </div>
                        <p className="mb-30 mb-xs-25">
                        <b>How do you determine the cost of a clinical evaluation/investigation/PMCF solutions?   </b><br/>

                        Costs are based on study scope, including site count, patient population, duration, and required services. We provide detailed estimates during the proposal stage.
                        </p>
                     
                    </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                    <div className="client-testimonial__item">
                        <div className="client-testimonial__item-icon mb-30 mb-xs-25">
                            <img className="img-fluid" src="assets/imgs/client-testimonial/qoute.png" alt="icon not found" />
                        </div>
                        <p className="mb-30 mb-xs-25">
                        <b>How about if we do not have budget for a study, are there any solutions? </b><br/>

                        For Class III, pre-CE devices, a clinical study is mandatory per regulations. However, for lower-risk devices and PMCF, we can explore more cost-effective alternatives.
                        </p>
                       
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="client-testimonial__slider-dot"></div>
        </div>
    );
};

export default TestimonialSlider;
