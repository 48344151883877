import React from 'react';
import './MedicalDeviceCard.css'; // Create and import a CSS file for additional styles
import { Link } from 'react-router-dom';

export const MedicalDeviceCard = () => {
  return (
    <>
      <section className="service-2 section-space">
        <div className="container">
          <div className="row  mb-xs-50 align-items-lg-end align-items-center">
            <div className="col-lg-6">
              <div className="section__title-wrapper mb-md-30 mb-mb-30 mb-xs-30">
                <h5 className="section__subtitle color-theme-primary mb-15 mb-xs-10 title-animation">
                  <img
                    src="assets/imgs/ask-quesiton/heart.png"
                    alt="icon not found"
                    className="img-fluid"
                  />{' '}
                  Medical Device Manufacturers
                </h5>
              </div>
            </div>
          </div>

          <div className="row">
                <h3 className="section__title mb-0 text-capitalize title-animation py-5">
                Our team members with expertise in CROs and medical devices work together to provide the most effective solutions for you. With years of industry expertise, our team of multidisciplinary specialists is well-equipped to deliver excellent regulatory and high-quality services in a timely manner. 
                </h3>
                <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2__item">
                <Link to="/notified-body-transition-QMS-improvement" className="service-2__item-media">
                  <img
                    className="img-fluid"
                    src="assets/imgs/service-2/service-1.jpg"
                    alt="image not found"
                  />
                </Link>
                <div className="service-2__item-content">
                  <h4 className="mb-20 mb-xs-15 d-flex align-items-start">
                    <Link to="/notified-body-transition-QMS-improvement">
                      <img
                        className="img-fluid mr-15"
                        src="assets/imgs/service-2/service-icon-1.png"
                        alt="icon not found"
                      />
                     a.) Notified Body Transition            <strong>( QMS Improvement & Gap identification)</strong>

                    </Link>
                  </h4>
                  <ul>
                    <li>
                    <ul>
                        <li>i. QMS</li>
                        <li>ii. Achieving success in quality management</li>
                    </ul>
                    </li>
                  
                </ul>
                <Link
                    className="rr-a-btn mt-30 mt-xs-25 d-block"
                    to="/notified-body-transition-QMS-improvement"
                  >
                    Read More <i className="fa-solid fa-circle-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
              <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2__item">
                <Link to="/notified-body-transition-medical-writing " className="service-2__item-media">
                  <img
                    className="img-fluid"
                    src="assets/imgs/service-2/service-1.jpg"
                    alt="image not found"
                  />
                </Link>
                <div className="service-2__item-content">
                  <h4 className="mb-20 mb-xs-15 d-flex align-items-start">
                    <Link to="/notified-body-transition-medical-writing ">
                      <img
                        className="img-fluid mr-15"
                        src="assets/imgs/service-2/service-icon-1.png"
                        alt="icon not found"
                      />
                     b.) Notified Body Transition
                    <strong>( Fill up the Gap by Medical Writing)</strong>
                    </Link>
                  </h4>
                  <ul>
                    <li>
                    <ul>
                        <li>i. Clinical Evaluation</li>
                        <li>ii. Clinical Investigation</li>
                        <br/>
                        <br/>
                    </ul>
                    </li>
                    <li>
                    
                    </li>
                </ul>
                <Link
                    className="rr-a-btn mt-30 mt-xs-25 d-block"
                    to="/notified-body-transition-medical-writing"
                  >
                    Read More <i className="fa-solid fa-circle-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2__item">
                <Link to="/notified-body-transition-device-registration" className="service-2__item-media">
                  <img
                    className="img-fluid"
                    src="assets/imgs/service-2/service-1.jpg"
                    alt="image not found"
                  />
                </Link>
                <div className="service-2__item-content">
                  <h4 className="mb-20 mb-xs-15 d-flex align-items-start">
                    <Link to="/notified-body-transition-device-registration">
                      <img
                        className="img-fluid mr-15"
                        src="assets/imgs/service-2/service-icon-1.png"
                        alt="icon not found"
                      />
                     c.) Notified Body Transition
                     <strong> (Medical Device Registration)</strong>                    </Link>
                  </h4>
                  <ul>
                    <li>
                    <ul>
                        <li>i. CE Registration</li>
                        <li>ii. FDA Registration</li>
                        <li>iii. NMPA Registration</li>
                        <li>iv. ROA Registration</li>
                    </ul>
                    </li>
                </ul>
                <Link
                    className="rr-a-btn mt-30 mt-xs-25 d-block"
                    to="/notified-body-transition-device-registration"
                  >
                    Read More <i className="fa-solid fa-circle-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/* Card 2 */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2__item">
                <Link to="/technical-document-update" className="service-2__item-media">
                  <img
                    className="img-fluid"
                    src="assets/imgs/service-2/service-2.jpg"
                    alt="image not found"
                  />
                </Link>
                <div className="service-2__item-content">
                  <h4 className="mb-20 mb-xs-15 d-flex align-items-start">
                    <Link to="/technical-document-update">
                      <img
                        className="img-fluid mr-15"
                        src="assets/imgs/service-2/service-icon-2.png"
                        alt="icon not found"
                      />
                      Technical Document Update
                    </Link>
                  </h4>
                  <ul>
                    <li>European MDR Requirements</li>
                    <li>United States FDA Requirements</li>
                    <li>China (NMPA) Requirements</li>
                    <li>Other International Markets</li>
                  </ul>
                  <Link
                    className="rr-a-btn mt-30 mt-xs-25 d-block"
                    to="/technical-document-update "
                  >
                    Read More <i className="fa-solid fa-circle-plus"></i>
                  </Link>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-2__item">
                <Link to="/digital-health-solutions " className="service-2__item-media">
                  <img
                    className="img-fluid"
                    src="assets/imgs/service-2/service-3.jpg"
                    alt="image not found"
                  />
                </Link>
                <div className="service-2__item-content">
                  <h4 className="mb-20 mb-xs-15 d-flex align-items-start">
                    <Link to="/digital-health-solutions ">
                      <img
                        className="img-fluid mr-15"
                        src="assets/imgs/service-2/service-icon-3.png"
                        alt="icon not found"
                      />
                      Digital Health Solutions
                    </Link>
                  </h4>
                  <ul>
                    <li>Development Processes</li>
                    <li>Regulatory Landscape</li>
                    <li>Technical Tools</li>
                    <br/>

                  </ul>
                  <Link
                    className="rr-a-btn mt-30 mt-xs-25 d-block"
                    to="/digital-health-solutions "
                  >
                    Read More <i className="fa-solid fa-circle-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
